import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { isEmpty, size } from 'lodash'
import { Grid, TablePagination, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  ConfirmationDialog,
  ErrorMessage,
  Gallery,
  InteractiveTutorial,
  Layout,
  SEO,
  SkeletonGallery,
} from 'components'

import { NOT_FOUND_ENDPOINT, SIGNUP_ENDPOINT } from 'constants/apiUrls'
import {
  MANAGE_STORE,
  TUTORIAL_NAMES,
} from 'constants/interactiveTutorialConstants'
import { ERROR_FETCHING_STORE } from 'constants/storeConstants'
import { getStoreItems } from 'services/StoreService'
import { getUserById } from 'services/UserService'

const storeDefaultState = {
  items: [],
  fetched: false,
}

const DESIGNS_LIMIT = 15

const useStyles = makeStyles(theme => ({
  container: {
    padding: '32px',
  },
  dynamicContainer: {
    backgroundColor: theme.palette.background.white,
    minHeight: '550px',
    height: '74vh',
    overflowY: 'hidden',
    boxShadow: `0px 24px 27px ${theme.palette.border.shadow}`,
    paddingTop: 'unset',
    '& .MuiGrid-root .MuiGrid-root': {
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up(1925)]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      [theme.breakpoints.up(2325)]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
      [theme.breakpoints.up(2725)]: {
        gridTemplateColumns: 'repeat(6, 1fr)',
      },
    },
    '& .designs .MuiCardActions-root': {
      padding: '0px 8px',
    },
    '& .designs .MuiGrid-root': {
      minHeight: '478px',
      height: '66vh',
      overflowY: 'auto',
      '& .MuiGrid-root': {
        width: '100%',
        '& .MuiPaper-root.MuiCard-root': {
          marginLeft: 'auto',
          marginRight: 'auto',
          '@media (max-width:280px)': {
            minWidth: '200px',
          },
        },
      },
    },
    '& .option-offers': {
      '& .MuiButtonBase-root:hover': {
        backgroundColor: theme.palette.background.white,
        color: theme.palette.text.primary,
      },
    },
  },
  formContainer: {
    flexShrink: 0,
  },
  fullWidth: {
    width: '100%',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
  footerStyles: {
    '& .MuiToolbar-root .MuiTypography-root, .MuiInputBase-root .MuiSelect-root': {
      fontWeight: 'bold',
    },
    '& .MuiToolbar-root .MuiTypography-root': {
      pointerEvents: 'none',
      '&focused': {
        backgroundColor: theme.palette.background.white,
      },
    },
    '& .MuiToolbar-root .MuiInputBase-root': {
      pointerEvents: 'none',
      '&focused': {
        backgroundColor: theme.palette.background.white,
      },
    },
    '& .MuiToolbar-root .MuiInputBase-root .MuiSvgIcon-root': {
      display: 'none',
    },
  },
}))

const StorePage = ({ location }) => {
  const classes = useStyles()
  const loggedUser = useSelector(state => state.userState.user)
  const isFetchingLoggedUser = useSelector(
    state => state.userState.isFetchingUser
  )
  const { pathname } = location
  const [displayPrivateInfo, setDisplayPrivateInfo] = useState(false)
  const [displayRegisterModal, setDisplayRegisterModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasNextItems, setHasNextItems] = useState(true)
  const [isFetchingStore, setIsFetchingStore] = useState(false)
  const [latestStoreItems, setLatestStoreItems] = useState([])
  const [storeUserId, setStoreUserId] = useState(null)
  const [storePage, setStorePage] = useState(0)
  const [storeTitle, setStoreTitle] = useState('')
  const [storeState, setStoreState] = useState(storeDefaultState)

  useEffect(() => {
    const fetchStoreName = async () => {
      try {
        const userStore = await getUserById(storeUserId)
        const {
          store_name: storeName,
          first_name: firstName,
          last_name: lastName,
        } = userStore
        if (storeName) {
          setStoreTitle(storeName)
        } else {
          setStoreTitle(`${firstName} ${lastName}'s Store`)
        }
      } catch (error) {
        setErrorMessage('Error fetching the store name, please try again later')
      }
    }

    if (storeUserId && isEmpty(storeTitle)) {
      fetchStoreName()
    }
  }, [storeTitle, storeUserId])

  useEffect(() => {
    const storeUserUid = pathname.split('/').pop()
    const fetchStoreModels = async () => {
      setIsFetchingStore(true)
      try {
        const { lastElement, items } = await getStoreItems(
          storeUserUid,
          DESIGNS_LIMIT,
          null
        )
        setStoreState({ fetched: true, items })
        setLatestStoreItems([lastElement])
        setHasNextItems(size(items) === DESIGNS_LIMIT)
      } catch (error) {
        setErrorMessage(ERROR_FETCHING_STORE)
        navigate(NOT_FOUND_ENDPOINT)
      }
      setIsFetchingStore(false)
    }

    if (storeUserUid) {
      setStoreUserId(storeUserUid)
      if (
        !isFetchingLoggedUser &&
        loggedUser &&
        storeUserId &&
        !storeState.fetched &&
        !size(storeState.items)
      ) {
        setDisplayPrivateInfo(storeUserUid === loggedUser.uid)
        fetchStoreModels()
      }
    } else {
      navigate(NOT_FOUND_ENDPOINT)
    }
  }, [isFetchingLoggedUser, loggedUser, pathname, storeState, storeUserId])

  const getStore = async lastElement => {
    let hasUpdated = false
    setIsFetchingStore(true)
    setErrorMessage('')
    try {
      const { lastElement: newLastElement, items } = await getStoreItems(
        storeUserId,
        DESIGNS_LIMIT,
        lastElement
      )
      if (size(items) > 0) {
        hasUpdated = true
        setHasNextItems(size(items) === DESIGNS_LIMIT)
        setStoreState({
          items: items,
          fetched: true,
        })
        if (lastElement) {
          setLatestStoreItems([...latestStoreItems, newLastElement])
        } else {
          setLatestStoreItems([newLastElement])
        }
      } else {
        setHasNextItems(false)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_STORE)
    }
    setIsFetchingStore(false)
    return hasUpdated
  }

  const handleStoreChangePage = async (event, newPage) => {
    let response = true
    if (newPage > storePage) {
      response = await nextStorePage(newPage)
    } else {
      await prevStorePage(newPage)
    }
    if (response) {
      setStorePage(newPage)
    }
  }

  const nextStorePage = async page => {
    const lastItem = latestStoreItems[page - 1]
    return getStore(lastItem)
  }

  const prevStorePage = async page => {
    const lastElement = page > 0 ? latestStoreItems[page - 1] : null
    latestStoreItems.pop()
    latestStoreItems.pop()
    setLatestStoreItems(latestStoreItems)
    await getStore(lastElement)
  }

  const displayStoreTutorial =
    loggedUser &&
    !loggedUser.isAnonymous &&
    loggedUser.tutorial_state &&
    !loggedUser.tutorial_state.manageStore &&
    loggedUser.isMaker

  const showRegisterRedirect = loggedUser && loggedUser.isAnonymous

  return (
    <Layout>
      <SEO title="Store" />
      {storeTitle && (
        <Grid container className={classes.titleContainer}>
          <Typography className={classes.fullWidth} variant="h4">
            {storeTitle}
          </Typography>
        </Grid>
      )}
      <Grid className={classes.container} container>
        <Grid className={classes.dynamicContainer} id="my-designs" item xs={12}>
          <ErrorMessage message={errorMessage} />
          <div className="designs">
            {isFetchingStore ? (
              <SkeletonGallery className={classes.formContainer} />
            ) : (
              <>
                <Gallery
                  addPrice={true}
                  className={classes.height}
                  displayPrivateInfo={displayPrivateInfo}
                  models={storeState.items}
                  showRegisterRedirect={showRegisterRedirect}
                  onClickShowRegisterRedirect={() =>
                    setDisplayRegisterModal(true)
                  }
                />
                <ConfirmationDialog
                  open={displayRegisterModal}
                  title="Do you want to build this? Please login/sign up"
                  confirmLabel="login/sign up"
                  cancelLabel="Cancel"
                  onAccept={() => navigate(SIGNUP_ENDPOINT)}
                  onCancel={() => setDisplayRegisterModal(false)}
                />
              </>
            )}
            <TablePagination
              className={classes.footerStyles}
              component="div"
              count={-1}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              labelRowsPerPage="Elements to display per page:"
              nextIconButtonProps={{ disabled: !hasNextItems }}
              page={storePage}
              rowsPerPage={DESIGNS_LIMIT}
              rowsPerPageOptions={[DESIGNS_LIMIT]}
              onChangePage={handleStoreChangePage}
            />
          </div>
        </Grid>
      </Grid>
      {displayStoreTutorial && (
        <InteractiveTutorial
          showTutorial={!loggedUser.tutorial_state.manageStore}
          steps={MANAGE_STORE}
          tutorial={TUTORIAL_NAMES.MANAGE_STORE}
        />
      )}
    </Layout>
  )
}

export default StorePage
